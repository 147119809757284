<template>
    <div class="ui-p24">

        <div v-if="!endProcess" class="ui-p24__header">
            <img src="~@/assets/icons/wallet-ico.svg" />
            <h2>Płatność</h2>
        </div>

        <div v-if="!endProcess" class="ui-p24__content">
            <div class="payment-logo">
                <img src="~@/assets/images/logo/przelewy24.svg" />
            </div>
            <div class="payment-summary">
                <p>Do zapłaty</p>
                <h4>{{price}} zł</h4>
            </div>
        </div>

        <div v-if="endProcess" class="ui-p24__content">
            <div class="payment-end">

                <div class="payment-end__image">
                    <div class="image-container">
                        <div v-if="!state || state === 'NEW'" class="image-container__ico image-container__ico--waiting rotating">
                            &#9676;
                        </div>
                        <div v-if="state === 'PAID' || state === 'COMPLETED'" class="image-container__ico image-container__ico--success">
                            &#10004;
                        </div>
                        <div v-if="state === 'CANCELED'" class="image-container__ico image-container__ico--error">
                            &#10005;
                        </div>
                        <img src="~@/assets/icons/wallet-ico.svg" />
                    </div>
                </div>

                <div v-if="!state || state === 'NEW'" class="payment-end__content">
                    <h1>Oczekuję na potwierdzenie płatności</h1>
                    <p>Pozostań na tej stronie do momentu potwierdzenia.</p>

                    <div class="payment-end__content__alert">Płatność powinna zostać otwarta w nowej karcie. W przypadku problemów sprawdź czy przeglądarka nie blokuje wyskakujących
                    okienek. O zablokowaniu wyskakującego okienka informuje ikona Zablokowano wyskakujące okienko na pasku adresu.</div>
                </div>

                <div v-if="state === 'PAID' || state === 'COMPLETED'" class="payment-end__content">
                    <h1>Dziękujemy za dokonanie płatności</h1>
                    <p>Twoja wizyta została zarezerwowana!</p>
                </div>

                <div v-if="state === 'CANCELED'" class="payment-end__content">
                    <h1>Płatność anulowana</h1>
                    <p>Twoja płatność została anulowana.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {

    name: 'p24',
    props: {

        endProcess: {
            type: Boolean,
            default: false,
        },

        price: {
            default: '',
        },

        state: {
            type: String,
            default: '',
        },

    },

};

</script>

<style lang="scss" src="./p24.scss" />
