export default {

    methods: {

        app__buildFilesList(files) {

            const list = files.map(file => {

                return {
                    type: 1,
                    original_filename: file.name,
                    uploaded_file: file.base64,
                };

            });

            return list;

        },

    },

};
