<template>
    <div class="view-finalization">
        <viewWrapper>
            <template #header>
                <textNav @click="manageBack()">Wróć</textNav>
            </template>
            <template #content>
                <splitedView>
                    <template #left>
                        <visitSummary />
                    </template>
                    <template #right>

                        <div class="finalization-content">

                          <div v-if="showDiscountInfo" class="discount-information">
                                <p>Przysługuje Ci jednorazowa zniżka w wysokości {{loyaltyDiscount.discount * 100}}%. Wykorzystaj ją teraz albo zbieraj punkty dla większych zniżek</p>
                                <btn type="next" @click="activateLoyaltyDiscount()">Użyj zniżki</btn>
                            </div>

                            <div v-if="showCancelDiscountInfo" class="discount-information">
                                <p>Wykorzystasz jednorazową zniżkę w wysokości {{loyaltyDiscount.discount * 100}}%.</p>
                                <btn type="next" @click="deactivateLoyaltyDiscount()">Cofnij użycie zniżki</btn>
                            </div>

                            <img v-if="!isPaymentRequired || allDone" class="mydr-logo" src="~@/assets/images/logo/logo.svg" />
                            <h4 v-if="!isPaymentRequired && !allDone">Potwierdź rezerwację wizyty.</h4>
                            <h4 v-if="allDone">Twoja wizyta została zarezerwowana.</h4>

                            <p24 v-if="selectedService && isPaymentRequired && !allDone" :price="loyaltyDiscount.active ? selectedService.discountedPrice : selectedService.price"/>

                            <ADService v-if="allDone"/>
                            <div class="pre-payment-info" v-if="selectedTerm.prePaymentInfo && !allDone">
                                <h2>Informacja od placówki:</h2>
                                <p class="">{{ selectedTerm.prePaymentInfo }}</p>
                             </div>

                            <div v-if="selectedService && isPaymentRequired && !allDone" class="finalization-content__processing">
                              <div class="">
                                <p>
                                  Usługi medyczne są świadczone przez lekarza lub placówkę, w której umawiasz wizytę. MyDr
                                  sp. z o.o. zapewnia jedynie techniczną obsługę umawiania wizyt. W razie reklamacji
                                  dotyczącej wizyty lub płatności, skontaktuj się bezpośrednio z placówką lub lekarzem.
                                  Odbiorcą płatności jest {{ facilityData }}.
                                  Masz prawo do odstąpienia od umowy zawartej na odległość.
                                  <a
                                    :href="`${appHost}static/Umowa_zawierana_na_odleglosc.pdf`"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Szczegóły znajdziesz tutaj
                                  </a>
                                </p>
                            </div>
                                <label>
                                    <input class="checkbox-blue" type="checkbox" v-model="agreements.payment"/>
                                    Oświadczam, że wyrażam zgodę na przetwarzanie moich danych osobowych przez
                                    {{facilityData}} oraz podmioty obsługujące dokonywaną przeze mnie płatność
                                    drogą elektroniczną za usługę opieki zdrowotnej dla celów związanych z tą płatnością.*
                                </label>
                                <label>
                                    <input class="checkbox-blue" type="checkbox" v-model="agreements.withdrawal"/>
                                     Oświadczam, iż zapoznałem się z <a :href="appHost+'static/Umowa_zawierana_na_odleglosc.pdf'"
                                                                        target="_blank" rel="noopener noreferrer">
                                  Prawem do odstąpienia od Umowy</a> *
                                </label>
                            </div>

                            <div class="finalization-content__errors">
                                <div v-if="$v.agreements.$error" class="input-row input-row--info" :class="{'input-row--error': $v.agreements.$error}">
                                    <small v-if="!$v.agreements.payment.checked || !$v.agreements.withdrawal.checked">
                                        Zaakceptuj wymagane zgody
                                    </small>
                                </div>
                            </div>

                        </div>

                        <div v-if="!allDone" class="finalization-footer">
                            <btn v-if="selectedService && isPaymentRequired" type="next">
                                <div class="btn-content" @click="processOrder()">
                                    <h4>potwierdź</h4>
                                    <p>i przejdź do płatności</p>
                                </div>
                            </btn>
                            <btn v-else type="next" @click="processOrder()">Potwierdź wizytę</btn>
                        </div>

                    </template>
                </splitedView>
            </template>
        </viewWrapper>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';

import buildFilesList from '@/mixins/local/buildFilesList';

import viewWrapper from '@/components/layout/viewWrapper/viewWrapper.vue';
import splitedView from '@/components/layout/splitedView/splitedView.vue';
import visitSummary from '@/components/functional/visitSummary/visitSummary.vue';

import p24 from '@/components/ui/p24/p24.vue';
import ADService from '@/components/functional/ADService/ADService.vue';

export default {

    name: 'Finalization',
    mixins: [validationMixin, buildFilesList],
    components: {
        viewWrapper,
        splitedView,
        visitSummary,
        p24,
        ADService,
    },
    data(){
        return {

            account: null,

            agreements: {
                payment: false,
                withdrawal: false,
            },

            allDone: false,

        };
    },
    validations: {

        agreements: {
            payment: {
                checked(value){
                    if (this.selectedService && this.isPaymentRequired) {
                        if (value === true) return true;
                        return false;
                    }

                    return true;
                },
            },
            withdrawal: {
                checked(value){
                    if (this.selectedService && this.isPaymentRequired){
                        if (value === true) return true;
                        return false;
                    }

                    return true;
                },
            },
        },

    },
    methods: {

        ...mapActions('user', ['setPaymentData', 'setProfile', 'setLoyaltyDiscount']),
        ...mapActions('global', ['setSelectedService']),

        init() {
            // when user has just registered and payment is not required
            if (!this.remoteAccounts && !this.paymentData) {
                this.allDone = true;
                return;
            }

            this.account = this.remoteAccounts?.find(acc => acc?.profile_id === this.selectedProfile?.id);
            if (this.account) this.getDiscount();

        },

        async processOrder(){

            this.$v.$touch();
            if (this.$v.$invalid) return;

            // when user has just registered and paymentData is provided
            if (this.selectedService && this.paymentData) {
                this.paymentRedirect(this.paymentData.url);
                return;
            }

            if (!this.account) await this.addProfileToFacility();
            this.addVisit();

        },

        paymentRedirect(url){

            setTimeout(() => {

                window.open(url, '_blank');

                this.$router.push({
                    path: '/payment_status',
                    query: {
                        remote_app: this.remoteAppData.remoteApp,
                        access_token: this.remoteAppData.accessToken,
                        order_id: this.paymentData.paymentId,
                    },
                });

            }, 1000);

        },

        addProfileToFacility(){

            return new Promise(reslv => {

                this.app__loaderEnqueue(() => {

                    return new Promise(resolve => {

                        this.app__getToken('mydr-token').then(token => {

                            this.$http.post('remote_account/register/', { profile_id: this.selectedProfile.id }, {
                                headers: {
                                    'Content-Type': 'Application/json',
                                    Authorization: `${token.token_type} ${token.access_token}`,
                                },
                                params: {
                                    selected_facility_token: this.remoteAppData.accessToken,
                                    remote_app: this.remoteAppData.remoteApp,
                                },
                            }).then(response => {
                                this.account = response.data;
                            }).catch(error => {
                                this.app__handleHttpFailureResponse(error);
                            }).finally(() => {
                                resolve();
                                reslv();
                            });

                        });

                    });

                });

            });

        },

        addVisit(){

            this.app__loaderEnqueue(() => {

                return new Promise(resolve => {

                    this.app__getToken('mydr-token').then(token => {

                        const details = new FormData();
                        const visitData = { ...this.selectedTerm.newVisit };

                        details.append('evisit', this.selectedTerm.evisit);
                        details.append('note', this.comment);

                        if (this.files) {
                            this.files.filesNames.forEach(file => {
                                details.append('uploaded_files', file.file);
                            });
                        }

                        if (this.selectedTerm.visit_kind === 'Prywatna' && (this.selectedTerm.prePayment || this.selectedTerm.evisit)) {
                            details.append('using_loyalty_program_discount', this.loyaltyDiscount.active && this.loyaltyDiscount.reset_points_after_use);
                            details.append('loyalty_program_discount', this.loyaltyDiscount.discount);
                        }

                        if (this.selectedService) details.append('private_service', this.selectedService.id);

                        delete visitData.specialties;

                        Object.keys(visitData).forEach(key => {
                            details.append(key, visitData[key]);
                        });

                        this.$http.post('visits/add/', details, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: `${token.token_type} ${token.access_token}`,
                            },
                            params: {
                                remote_account_id: this.account.id,
                            },
                        }).then(response => {

                            // this.showResponse(response.data.state);
                            if (response.data.redirect_url) {

                                this.setPaymentData({
                                    url: response.data.redirect_url,
                                    paymentId: response.data.order_id,
                                    price: response.data.price,
                                    useDiscount: response.data.using_loyalty_program_discount,
                                    discountPrice: response.data.price_after_loyalty_program_discount,
                                    state: response.data.state,
                                    date: response.data.date,
                                });

                                this.paymentRedirect(response.data.redirect_url);

                                return;
                            }

                            this.allDone = true;

                        }).catch(error => {

                            if (error.response && [429].includes(error.response.status)) {
                                const message = 'Dzienny limit rezerwacji został przekroczony. Spróbuj zarezerwować ten termin jutro.';

                                this.$notifications.push({
                                    title: 'Nowa wizyta',
                                    content: message,
                                    type: 'error',
                                });

                                return;
                            }

                            this.visitFailureAction(error);

                        }).finally(() => {
                            resolve();
                        });

                    });

                });

            });

        },

        visitFailureAction(error) {

            const { response } = error;
            let message;

            const messages = response.data;

            if (messages && messages.detail){

                Object.values(messages.detail).forEach(mess => {
                    this.$notifications.push({
                        title: 'Nowa wizyta',
                        content: mess,
                        type: 'error',
                    });
                });

                return;

            }

            if (response.status === 403) {

                message = 'Przekroczyłeś limit umówionych wizyt!';
                this.$notifications.push({
                    title: 'Nowa wizyta',
                    content: message,
                    type: 'error',
                });
                return;

            }

            if (response.status === 400) {

                const responseMsg = response.data.non_field_errors[0];

                switch (responseMsg){

                case 'SAME_DAY_ERROR':
                    message = 'Lekarz nie pozwala na przeprowadzanie dwóch wizyt tego samego dnia.';
                    break;

                case 'SAME_DAY_ERROR_FACILITY':
                    message = 'Placówka nie pozwala na przeprowadzanie dwóch wizyt tego samego dnia.';
                    break;

                case 'PATIENT_EXCEED_THE_LIMIT':
                    message = 'Nie możesz mieć więcej niż trzech umówinych wizyty w danej placówce.';
                    break;

                case "OUTDATED_DISCOUNT_INFO_ERROR":
                    message = 'Dane zniżki są nieaktualne. Spróbuj ponownie.';
                    break;

                case 'UNTRUSTED_PATIENT_EXCEED_THE_LIMIT':
                    message = 'Nie możesz umówić więcej wizyt w wybranej placówce. Limit zostanie zwiększony po wizycie w placówce.';
                    break;

                default:
                    message = 'Niestety twoje żądanie zakończyło się niepowodzeniem. Wybrany termin został już zajęty.';

                }

                this.$notifications.push({
                    title: 'Nowa wizyta',
                    content: message,
                    type: 'error',
                });

                return;

            }

            this.app__handleHttpFailureResponse(error);
        },

        manageBack(){

            if (this.profiles && this.profiles.length > 1){
                this.$router.push('/profiles');
                return;
            }

            this.$router.push('/informations');

        },

        activateLoyaltyDiscount() {
            const discount = { ...this.loyaltyDiscount };
            discount.active = true;
            this.setLoyaltyDiscount(discount);
        },

        deactivateLoyaltyDiscount() {
            const discount = { ...this.loyaltyDiscount };
            discount.active = false;
            this.setLoyaltyDiscount(discount);
        },

        getDiscount(){

            if (this.selectedTerm.visit_kind === 'Prywatna' && this.selectedService){

                this.app__loaderEnqueue(() => {

                    return new Promise(resolve => {

                        this.app__getToken('mydr-token').then(token => {

                            this.$http.get('visits/get_patient_loyalty_program/', {
                                headers: {
                                    'Content-Type': 'Application/json',
                                    Authorization: `${token.token_type} ${token.access_token}`,
                                },
                                params: {
                                    selected_facility_token: this.remoteAppData.accessToken,
                                    remote_app: this.remoteAppData.remoteApp,
                                    remote_account_id: this.account.id,
                                },
                            }).then(response => {

                                const loyaltyDiscount = response.data;
                                loyaltyDiscount.active = (!loyaltyDiscount.reset_points_after_use && loyaltyDiscount.discount > 0);
                                this.setLoyaltyDiscount(loyaltyDiscount);

                                const service = this.selectedService;
                                service.discountedPrice = (service.numberPrice * (1 - loyaltyDiscount.discount)).toFixed(2).replace('.', ',');
                                this.setSelectedService(service);

                            }).catch(error => {
                                this.app__handleHttpFailureResponse(error);
                            }).finally(() => {
                                resolve();
                            });

                        });

                    });

                });

            }

        },

    },
    computed:{
        ...mapState('user', ['remoteAccounts', 'paymentData', 'selectedProfile', 'profiles', 'loyaltyDiscount', 'comment', 'files']),
        ...mapState('global', ['remoteAppData', 'selectedTerm', 'selectedService']),

        appHost() {
            return process.env.VUE_APP_HOST;
        },

        facilityData(){
            const { department } = this.selectedTerm;
            return `${department.facility_name}, ${department.address}`;
        },

        isPaymentRequired() {
            return this.selectedTerm.prePayment;
        },

        showDiscountInfo(){
            return this.isPaymentRequired && this.selectedService && this.loyaltyDiscount.discount && this.loyaltyDiscount.reset_points_after_use && !this.loyaltyDiscount.active;
        },

        showCancelDiscountInfo(){
            return this.isPaymentRequired && this.selectedService && this.loyaltyDiscount.discount && this.loyaltyDiscount.reset_points_after_use && this.loyaltyDiscount.active;
        },
    },
    created(){

        this.init();

    },

};

</script>

<style lang="scss" src="./Finalization.scss" />
